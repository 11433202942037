<template>
    <div>
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-show="!isSpinner">
            <!-- Personal Information -->
            <b-card>
                <b-card>
                    <!-- Profile Information -->
                    <b-row>
                        <b-col md="2">
                            <img v-if='userData.imageUrl !== ""' :src="userData.imageUrl" alt="profile image" width="160px" height="145px" class="profile_img">
                            <img v-else src="@/assets/images/avatars/defaultUser.jpg" alt="profile image" width="160px" height="145px" class="profile_img">
                        </b-col>
                        <b-col>
                            <b-row>
                                <!-- <b-col md="4" lg="3">
                                    <b-form-group label="User ID">
                                        {{userData.uniqueId}}
                                    </b-form-group>
                                </b-col> -->
                                <b-col md="4" lg="3">
                                    <b-form-group label="Name">
                                        <span class="output_span">{{userData.name}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Email">
                                         <span class="output_span">{{userData.email}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Mobile No.">
                                         <span class="output_span">{{`${userData.countryPhoneCode ? userData.countryPhoneCode.dialCode : '+91'} ` + (userData.countryPhoneCode ? formatPhoneNumber(userData.mobileNumber,userData.countryPhoneCode.mask) : formatPhoneNumber(userData.mobileNumber,'99999 99999'))}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Date of Birth" v-if="userData">
                                         <span class="output_span">{{userData.dob.split("-")[2]}} {{months[userData.dob.split("-")[1]-1]}}, {{userData.dob.split("-")[0]}}</span>
                                            <!-- {{userData.displayDob}} -->
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Gender">
                                        <span class="output_span">{{userData.gender}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Communication Address" v-if="userData">
                                         <span class="output_span">{{`${userData.addressLine1}, ${userData.addressLine2}, ${userData.city.name}, ${userData.state.name} - ${userData.pincode}`}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Intake" v-if="userData">
                                         <span class="output_span">{{userData.intake||'--'}}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="3">
                                    <b-form-group label="Year" v-if="userData">
                                         <span class="output_span">{{userData.year||'--'}}</span>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        
                    </b-row>
                
                </b-card>
            
                <!-- Educational Details -->
                <b-card
                    header="Education Details"
                    header-bg-variant="#f4f4f4"
                    style="background-color:#f2f2f2"
                    v-if="userData.educationDetails"
                >
                    <b-row  class="card-title-border-bottom" v-for="(data,index) in userData.educationDetails" :key="index">
                        <!-- <b-col md="3">
                            <b-form-group label="Education">
                                 <span class="output_span">{{data.education}}</span>
                            </b-form-group>
                        </b-col> -->
                        <b-col md="3" v-if="data.education && data.edId == undefined">
                            <b-form-group label="Education">
                                {{data.education}}
                            </b-form-group>
                        </b-col>
                        <b-col md="3" v-if="data.education && data.edId">
                            <b-form-group label="Degree Name">
                                {{data.education}}
                            </b-form-group>
                        </b-col>
                        <b-col md="3" v-if="data.branchOrStreamOrFieldName && data.bsfId">
                            <b-form-group label="Branch/Stream/Field Name">
                                {{data.branchOrStreamOrFieldName}}
                            </b-form-group>
                        </b-col>

                        <b-col md="3">
                            <b-form-group label="School/College Name">
                                <span class="output_span"> {{data.schoolOrCollegeName ? data.schoolOrCollegeName : '-'}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Board/University Name">
                                <span class="output_span"> {{data.boardUniversityName}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group :label="data.cgpa_percentage">
                                <span class="output_span"> {{data.cgpa_percentageValue}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Year of Passing">
                                 <span class="output_span">{{data.yearOfPassing}}</span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-row class="match-height">
                    <b-col>
                        <b-row>
                            <!-- IELTS/TOEFL -->
                            <b-col md="12">
                                <b-card
                                header="Language"
                                header-bg-variant="#f2f2f2"
                                style="background-color:#f2f2f2"
                                >
                                    <!-- <b-row md="6" class="card-title-border-bottom">
                                        <b-col md="12">
                                            <b-form-group label="Language">
                                                {{userData.language}}
                                            </b-form-group>
                                        </b-col>
                                    </b-row> -->
                                    <b-row md="6" class="card-title-border-bottom">
                                        <b-col md="12">
                                            <b-form-group label="German Language">
                                                 <span class="output_span">{{userData.germanLanguage}}</span>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                            <!-- German Language -->
                            <b-col md="12">
                                <b-card
                                    header="Exams"
                                    header-bg-variant="#f2f2f2"
                                    style="background-color:#f2f2f2"
                                    v-if="userData.exams"
                                >
                                    <b-row class="card-title-border-bottom" v-show="userData.exams.length > 0">
                                        <b-col md="6" v-for="(data,index) in userData.exams" :key="index">
                                            <b-form-group :label="data.title">
                                                 <span class="output_span">{{parseFloat(data.score).toFixed(1)}}</span>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row class="card-title-border-bottom" v-show="userData.exams.length <= 0">
                                        <b-col md='6'>
                                            <span class="output_span">None</span>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Field of Interest -->
                    <b-col md="6">
                        <b-row>
                            <b-col md="12">
                                <b-card
                                    header="Student Interest"
                                    header-bg-variant="#f2f2f2"
                                    style="background-color:#f2f2f2"
                                    v-if="userData.exams"
                                >
                                    <b-row md="6" class="card-title-border-bottom">
                                        <b-col md="12">
                                            <b-form-group label="Area of Interest">
                                                <span class="output_span">{{userData.areaOfInterest||'--'}}</span>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                            <b-col md="12">
                                <b-card
                                    header="Field of Interest"
                                    header-bg-variant="#f2f2f2"
                                    style="background-color:#f2f2f2"
                                    v-if="false"
                                >
                                    <b-row md="6"  class="card-title-border-bottom">
                                        <b-col v-for="(data,index) in userData.fieldOfInterests" :key="index" :class="{'bottom-border':index!=userData.fieldOfInterests.length-1}" md="12">
                                            <b-form-group :label="data.field.name">
                                                <span class="output_span"> {{data.course.name}}</span>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Payment -->
                    <b-col md="6" v-if="false">
                        <b-card
                            style="background-color:#f2f2f2"
                        >
                            <div class="card-header" style="padding:0px 0px 1rem 0px;">
                                Payment
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    class="btn-icon"
                                    style="background-color:#0d4ea0!important"
                                    v-b-modal.modal-add-payment
                                >
                                    Add Payment
                                </b-button>
                            </div>
                            <div>
                                <b-row
                                md="6"
                                class="card-title-border-bottom"
                                v-for="(data,index) in userData.payments" :key="index"
                                >
                                    <b-col md="4">
                                        <b-form-group label="Payment Received Date">
                                             <span class="output_span">{{data.paymentReceivedDate.split("-")[2]}} {{months[data.paymentReceivedDate.split("-")[1]-1]}} {{data.paymentReceivedDate.split("-")[0]}}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group label="Mode Of Payment">
                                             <span class="output_span">{{data.modeOfPayment}}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4" v-if="data.modeOfPayment=='Cheque'">
                                        <b-form-group label="Bank Name">
                                             <span class="output_span">{{data.bankName}}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4" v-if="data.modeOfPayment=='Cheque'">
                                        <b-form-group label="Cheque No.">
                                             <span class="output_span">{{data.chequeNo}}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group label="Rupees (INR)">
                                            <span class="output_span"> {{parseFloat(data.rupees).toFixed(2)}}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group label="Remark" v-if="data.modeOfPayment == 'Other'">
                                             <span class="output_span">{{data.remark}}</span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>

            <!--Add Payment -->
            <b-modal
                id="modal-add-payment"
                cancel-variant="outline-secondary"
                ok-title="Save"
                cancel-title="Cancel"
                centered
                title="Add Payment"
                :no-close-on-backdrop="true"
                @hidden="cancelPayment"
                @ok="(e)=>{e.preventDefault(); addPayment(e)}"
            >
            <div>
                <validation-observer ref="paymentValidator">
                <!-- form -->
                <b-form @submit.stop.prevent="addPayment">
                    <b-row v-if="addPaymentData">
                        <!-- Date -->
                        <b-col md="6">
                            <b-form-group label="Payment Received Date">
                            <validation-provider
                                #default="{ errors }"
                                name="payment received date"
                                rules="required"
                            >
                                <b-form-input
                                    id="example-input"
                                    v-model="addPaymentData.paymentReceivedDate"
                                    onkeydown="return false"
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                    :state="errors.length > 0 ? false:null"
                                    :max="getMaxDate()"
                                     class="input-date"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Mode Of Payment -->
                        <b-col md="6">
                            <b-form-group label="Mode Of Payment">
                            <validation-provider
                                #default="{ errors }"
                                name="mode of payment"
                                rules="required"
                            >
                            <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                            <!-- <div> -->
                                <b-form-radio
                                    v-model="addPaymentData.modeOfPayment"
                                    plain
                                    name="mode of payment"
                                    value="Cash"
                                >
                                    Cash
                                </b-form-radio>
                                <b-form-radio
                                    v-model="addPaymentData.modeOfPayment"
                                    plain
                                    name="mode of payment"
                                    value="Cheque"
                                >
                                    Cheque
                                </b-form-radio>
                                <b-form-radio
                                    v-model="addPaymentData.modeOfPayment"
                                    plain
                                    name="mode of payment"
                                    value="Other"
                                >
                                    Other
                                </b-form-radio>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Bank Name -->
                        <b-col md="6" v-if="addPaymentData.modeOfPayment == 'Cheque'">
                            <b-form-group label="Bank Name">
                            <validation-provider
                                #default="{ errors }"
                                name="bank name"
                                rules="required|alpha_spaces"
                            >
                                <b-form-input
                                    v-model="addPaymentData.bankName"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Text"
                                    @blur="addPaymentData.bankName=addPaymentData.bankName.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Cheque No. -->
                        <b-col md="6"  v-if="addPaymentData.modeOfPayment == 'Cheque'">
                            <b-form-group label="Cheque No.">
                            <validation-provider
                                #default="{ errors }"
                                name="cheque no."
                                rules="required|integer|min:6|max:6"
                            >
                                <b-form-input
                                    v-model="addPaymentData.chequeNo"
                                    name="cheque no."
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter number"
                                    @blur="addPaymentData.chequeNo=addPaymentData.chequeNo.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Rupees (INR) -->
                        <b-col md="6">
                            <b-form-group label="Rupees (INR)">
                            <validation-provider
                                #default="{ errors }"
                                name="rupees"
                                rules="required|min_value:1"
                            >
                                <b-form-input
                                    v-model="addPaymentData.rupees"
                                    name="rupees"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="0.00"
                                    @blur="addPaymentData.rupees=addPaymentData.rupees.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Remark -->
                        <b-col md="12">
                            <b-form-group label="Remark" v-if="addPaymentData.modeOfPayment=='Other'">
                            <validation-provider
                                #default="{ errors }"
                                name="remark"
                                rules=""
                            >
                                <b-form-textarea
                                    v-model="addPaymentData.remark"
                                    name="remark"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Text"
                                    @blur="addPaymentData.remark=addPaymentData.remark.trim()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
                </validation-observer>
            </div>
            </b-modal>


             <!--Create Account Modal -->
            <b-modal
                id="modal-create-account"
                cancel-variant="outline-secondary"
                ok-title="Yes"
                cancel-title="No"
                centered
                title=""
                :no-close-on-backdrop="true"
                @cancel="selectedUser=''"
                @ok="()=>{$bvModal.show('modal-select-details')}"
            >
            <div class="create_account_img">
                <img src="@/assets/images/erflog/Create_Account.png" alt="student">
            </div>
                <div style="text-align: center;" v-if="selectedUser!=''">
                <label class="s_modal_title">Create Account</label>
                <p>Are you sure you want to create account for <span>{{selectedUser.name}} ?</span></p>
                </div>
            </b-modal>


            <!--Select Details -->
            <b-modal
                id="modal-select-details"
                cancel-variant="outline-secondary"
                ok-title="Save & Invite"
                cancel-title="Cancel"
                centered
                title="Select Details"
                :cancel-disabled="creatingAccount"
                :ok-disabled="creatingAccount"
                no-close-on-backdrop
                @hidden="selectedUser='',documentModule=null"
                @ok="(e)=>{e.preventDefault(); createAccount(e);}"
            >
                <b-spinner v-if="creatingAccount" variant="primary" style="position:absolute;left:50%;z-index:999;width:50px;height:50px;"/>
                <div>
                <validation-observer ref="studentPersonInformation">
                <!-- form -->
                <b-form @submit.stop.prevent="createAccount">
                    <b-row>
                    <!-- Representative -->
                    <b-col md="6">
                        <b-form-group label="Representative">
                        <validation-provider
                            #default="{ errors }"
                            name="representative"
                            rules=""
                        >
                            <b-form-select
                                v-model="representative"
                                :options="representativeOptions"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Select Document Module -->
                    <b-col md="6">
                        <b-form-group label="Select Document Module">
                        <validation-provider
                            #default="{ errors }"
                            name="document module"
                            rules="required"
                        >
                            <v-select
                                v-model="documentModule"
                                label="title"
                                multiple
                                :options="documentModuleOptions"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    </b-row>
                </b-form>
                </validation-observer>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,BDropdown, BDropdownItem,
  BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BBadge,BFormTextarea, BTable,BSpinner,
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,min_value,numeric,min,alpha_spaces } from 'vee-validate/dist/rules';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)

import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
// import firebase from "firebase";
const db = firebase.firestore()
import moment from 'moment'
//Import Sub Components
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';
export default {
    directives: {
        Ripple
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BBadge,
        BFormTextarea,
        BDropdown,
        BDropdownItem,
        BTable,
        BSpinner,
        vSelect
    },   
    data() {
        return {
            isSpinner:false,
            docId:this.$route.params.id,
            userData:'',
            representative:null,
            representativeOptions: [{value:null,text:'Select',disabled:true}],
            months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            addPaymentData:{
                paymentReceivedDate:'',
                modeOfPayment: '',
                bankName: '',
                chequeNo:'',
                remark:'',
                rupees: ''
            },
            applicationData:[],
            applicationColumn:[
                {key:'applicationNumber', label:'Application No.'},
                {key:'intake', label:'Intake'},
                {key:'selectedCollege', label:'No. of Suggested Course'},
                {key:'shortListedColleges', label:'No. of Shortlist Course'},
                {key:'ApplyDate', label:'Application Date'},
                {key:'application_Status', label:'Application Status'},
                {key:'id', label:''},
            ],
            creatingAccount:false,
            currenUserObject:{},
            documentModule:null,
            documentModuleOptions: [],
            selectedUser:''
        }
    },
    watch:{
        $route: function () {
            this.getData();          
        }
    },
    created() {
        var self = this

        if(!self.$route.params.id)
        {
            self.$router.push({name:'student-inquiry'})
        }
        // console.log(self.$route.params.id)
        self.getData();

        db
            .collection(dbCollections.USERS)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                let representativeArray = [];
                let employeeArry = [];
                querySnapshot.forEach(doc=>{
                    if(!doc.data().isDeleted && doc.data().id == firebase.auth().currentUser.uid){
                        self.currenUserObject = doc.data()
                    }
                    if(!doc.data().isDeleted && doc.data().role == 'employee') {
                        if(doc.data().representative){
                            employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
                        } else {
                            employeeArry.push({id:doc.data().id})
                        }
                    }
                })

                function findRelatedRIds(id) {
                    const relatedRIds = [];
                    relatedRIds.push(id)
                    const visitedIds = new Set();

                    function traverseRelations(currentId) {
                        visitedIds.add(currentId);
                        
                        for (const relation of employeeArry) {
                            if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                                relatedRIds.push(relation.id);
                                traverseRelations(relation.id);
                            }
                        }
                    }
                    
                    traverseRelations(id);
                    
                    return relatedRIds;
                }

                let id = firebase.auth().currentUser.uid
                representativeArray = findRelatedRIds(id)

                querySnapshot.forEach(doc=>{
                    if(!doc.data().isDeleted && doc.data().role=='employee' && (self.currenUserObject.role == 'employee' && representativeArray.includes(doc.data().id) || self.currenUserObject.role == 'admin') )
                    {
                        self.representativeOptions.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id,phoneNo:doc.data().personalPhoneNo},text:doc.data().name})
                    }
                })
            })

        db
            .collection(dbCollections.DOCUMENTCATEGORY)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                querySnapshot.forEach(doc=>{
                    if(!doc.data().isDeleted)
                    {
                    self.documentModuleOptions.push({title:doc.data().categoryName,id:doc.data().id})
                    }
                })
            })
            .catch(error=>{
                console.log(error)
            })

        // BLOCK UNBLOCK USER
        self.$root.$on('blockUser',()=>{    
            let updateObj = null;      
            this.getData();
            if(self.userData !== null && self.userData !== undefined){       
            let element = self.userData; 
            if(Object.keys(element).length > 0){                    
                if(element.isActive !== undefined){
                    updateObj = {
                        'isActive': !element.isActive
                    }
                }else{
                    updateObj = {                           
                        'isActive': false
                    }                         
                }
                if(updateObj.isActive !== undefined){  
                    this.userUpdateActive(updateObj,callBack=>{
                        if(callBack == true){
                            this.$root.$emit('stopSpinner')
                            this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'User Inactive Successfully',
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            },
                            })
                            return
                        }else{
                            this.$root.$emit('stopSpinner')
                            return
                        }
                    }) 
                }else{
                    this.$root.$emit('stopSpinner')
                    return
                }
            }else{
                self.getData();
                this.$root.$emit('stopSpinner')
            }
            }else{
                self.getData();
                this.$root.$emit('stopSpinner')
            }   
        })
        // UNBLOCK USER
        self.$root.$on('unBlockUser',()=>{
            let updateObj = null;      
            this.getData();
            if(self.userData !== null && self.userData !== undefined){           
            let element = self.userData;       
            if(Object.keys(element).length > 0){                    
                if(element.isActive !== undefined){
                    updateObj = {
                        'isActive': !element.isActive
                    }
                }else{
                    updateObj = {                           
                        'isActive': false
                    }                         
                }
                if(updateObj.isActive !== undefined){     
                    this.userUpdateActive(updateObj,callBack=>{
                        if(callBack == true){                            
                            this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'User active Successfully',
                                icon: 'CoffeeIcon',
                                variant: 'success',                               
                            },
                            }) 
                            self.getData();
                            this.$root.$emit('stopSpinner')
                            return
                        }else{
                            self.getData();
                            this.$root.$emit('stopSpinner')
                        }
                    }) 
                }else{
                    this.$root.$emit('stopSpinner')
                }
            }else{
                this.$root.$emit('stopSpinner')
            }
            }else{
                this.$root.$emit('stopSpinner')
            }
                 
        })
        // CREATE APPLICATION
        self.$root.$on('createApplicationStidentMgmt',()=>{
            self.$router.push({'name':'create-new-application'})
        })
        self.$root.$off('createAccountInquiry')
        self.$root.$on('createAccountInquiry',()=>{
            self.selectedUser = {...self.userData}
            self.$bvModal.show('modal-create-account')
        })
    },
    methods: {
        formatPhoneNumber,
        getIntakeNo(cb){
            var self = this
            db
                .collection(dbCollections.USERS)
                .orderBy('createdAt')
                .get()
                .then((querySnapshot)=>{
                    var newIntakeNo=0
                    if(!querySnapshot.empty)
                    {
                        querySnapshot.forEach(doc=>{
                        if(!doc.data().isDeleted && doc.data().role=="student")
                        {
                            var uniqueId = doc.data().uniqueId
                            if(uniqueId>=newIntakeNo)
                            {
                                newIntakeNo = parseInt(uniqueId)+1
                            }
                            var zeroes = 6-newIntakeNo.toString().length

                            for (let i = 0; i < zeroes; i++) {
                                newIntakeNo='0'+newIntakeNo
                            }
                        }
                        })
                        if(newIntakeNo==0)
                        {
                        newIntakeNo='000001'
                        }
                    }
                    else
                    {
                    newIntakeNo='000001'
                    }
                    cb(newIntakeNo)
                })
            },
        createAccount(e){
            var self = this

            self.creatingAccount=true
            var currentUsers = JSON.parse(localStorage.getItem('currentUser'));

            self.$refs.studentPersonInformation.validate().then(success=>{
                if(success){
                    self.getIntakeNo(uniqueId=>{
                        var userData = {...self.selectedUser,representative:self.representative,documentModule:self.documentModule,uniqueId:uniqueId}
                        self.$axios.post(dbCollections.APIURL+'createNewStudent',{email:userData.email,name:userData.name})
                        .then((resp)=>{
                            if(resp.data.status==200)
                            {
                                db
                                .collection('users')
                                .doc(resp.data.data.uid)
                                .set({
                                    'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                    'docFiles':userData.docFiles||[],
                                    'imageUrl':userData.imageUrl,
                                    'imageName':userData.imageName,
                                    'firstName':userData.firstName,
                                    'lastName':userData.lastName,
                                    'name':userData.name,

                                    'intake':userData.intake,
                                    'year':userData.year,

                                    'email':userData.email,
                                    'mobileNumber':userData.mobileNumber,
                                    'dob':userData.dob,
                                    'gender':userData.gender,
                                    'addressLine1':userData.addressLine1,
                                    'addressLine2':userData.addressLine2,
                                    'country':userData.country,
                                    'state':userData.state,
                                    'city':userData.city,
                                    'pincode':userData.pincode,
                                    'educationDetails':userData.educationDetails,
                                    'language':userData.language,
                                    'germanLanguage':userData.germanLanguage,
                                    'exams':userData.exams,
                                    'fieldOfInterests':userData.fieldOfInterests,
                                    'role':'student',
                                    'representative':userData.representative||'',
                                    'documentModule':userData.documentModule,
                                    'status':userData.status,
                                    'uniqueId':userData.uniqueId,
                                    'isDeleted':false,
                                    'id':resp.data.data.uid,

                                    'areaOfInterest':userData.areaOfInterest||'',
                                    'countryPhoneCode': userData.countryPhoneCode ? userData.countryPhoneCode : { ru:"Индия",lt:"Indija",tr:"Hindistan", en: 'India',flag: '🇮🇳',code: 'IN', dialCode: '+91', mask: '99999 99999' },

                                    'isActive':true
                                })
                                .then(()=>{
                                    let notificationObj = {
                                        title: `Student Created`,
                                        type: 'studentCreatedFromInquiry',
                                        userId: resp.data.data.uid,
                                        currentUser:firebase.auth().currentUser.uid
                                    }
                                    if(userData.representative) {
                                        notificationObj.message = `Your profile has been created by ${currentUsers.fullName} under ${userData.representative.name}`;
                                    } else {
                                        notificationObj.message = `Your profile has been created by ${currentUsers.fullName}`;
                                    }
                                    this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                                    }).catch((error)=>{
                                        console.error(error);
                                    })
                                    db
                                    .collection(dbCollections.INQUIRY)
                                    .doc(self.selectedUser.id)
                                    .update({
                                        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                        isDeleted:false,
                                        accountCreated:true
                                    })
                                    .then(()=>{
                                        self.$router.push({name:'student-inquiry'})
                                        self.representative=null
                                        self.documentModule=null
                                        self.$bvModal.hide('modal-select-details')
                                        self.$root.$emit('showToastMessage','Account created successfully.','success')
                                        self.creatingAccount=false
                                    })
                                    .catch(error=>{
                                        self.$root.$emit('showToastMessage',error.message,'danger')
                                        console.log(error)
                                        self.creatingAccount=false
                                    })
                                    self.payments=[{
                                        paymentReceivedDate:'',
                                        modeOfPayment: 'Cash',
                                        bankName: '',
                                        chequeNo:'',
                                        remark:'',
                                        rupees: ''
                                    }]
                                })
                                .catch(error=>{
                                    self.$root.$emit('showToastMessage',error.message,'danger')
                                    self.creatingAccount=false
                                    console.log(error)
                                })
                            }
                            else
                            {
                                self.$root.$emit('showToastMessage',resp.data.msg,'danger')
                                self.selectedUser=''
                                self.payments=[{
                                    paymentReceivedDate:'',
                                    modeOfPayment: 'Cash',
                                    bankName: '',
                                    chequeNo:'',
                                    remark:'',
                                    rupees: ''
                                }]
                                self.representative=null
                                self.documentModule=null
                                self.$bvModal.hide('modal-select-details')

                                self.creatingAccount=false
                            }
                        })
                        .catch(error=>{
                            self.payments=[{
                                paymentReceivedDate:'',
                                modeOfPayment: 'Cash',
                                bankName: '',
                                chequeNo:'',
                                remark:'',
                                rupees: ''
                            }]
                            self.$root.$emit('showToastMessage',error.message,'danger')
                            self.selectedUser=''
                            self.representative=null
                            self.documentModule=null
                            console.log(error)
                            self.creatingAccount=false
                        })
                    })
                }
                else{
                    e.preventDefault()
                    self.creatingAccount=false
                }
        });
        },
        getData(){
            var self = this;

            self.isSpinner = true

            db
            .collection(dbCollections.INQUIRY)
            .doc(self.docId)
            .get()
            .then((doc)=>{
                // let disp = moment(doc.data().dob.toDate()).format('DD MMM yyyy') 
                let elements = doc.data();
                // if(elements.imageUrl == '' || elements.imageUrl == undefined){
                //     elements.imageUrl = 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/DefaultUser%2FdefaultUser.jpg?alt=media&token=c481d71c-8ec8-449d-afe7-6a5d54c4560f';
                // }
                // if(elements.imageName == '' || elements.imageName == undefined){
                //     elements.imageName = 'defaultUser.jpg'
                // }
                self.userData=elements;
                // if(self.userData.ima)
                if(self.userData !== null){                
                    self.$root.$emit('editUserData',doc.data())
                }
                // self.userData.displayDob = disp;

            // GET SPECIFIC USERS APPLICATION DATA [JYOTI 06-12-21]
            getQuery.getDataWithSpecificWhereCondition(dbCollections.APPLICATIONS,'selectedStudent.id','==',self.docId,res=>{ 
                self.isSpinner=false
                this.applicationData = [];
                if(res){
                    res.forEach(elements=>{
                        if(elements){
                            elements.ApplyDate = moment(elements.createdAt.toDate()).format('DD MMM, YYYY')
                            this.applicationData.push(elements)
                            elements.shortlistPeding = 0;
                            elements.shortlistinProgress = 0;
                            elements.shortlistSuccessfull = 0;
                            elements.shortlistreject = 0;
                            elements.displayStatus = ''
                            if(elements.application_Status.toLowerCase()=='pending'){
                                elements.displayStatus = 'Pending'
                            }
                            if(elements.application_Status.toLowerCase()=='inprogress'){
                                elements.displayStatus = 'In Progress'
                            }
                            if(elements.application_Status.toLowerCase()=='admissionsuccessful'){
                                elements.displayStatus = 'Admission Successful'
                            }
                            if(elements.application_Status.toLowerCase()=='admissionrejected'){
                                elements.displayStatus = 'Admission Rejected'
                            }
                            // , 'successBackGround': (data.item.application_Status.toLowerCase()=='admissionsuccessful'), 'progressBackGround':(data.item.application_Status.toLowerCase()=='inprogress'), 'rejectedBackGround':(data.item.application_Status.toLowerCase()=='admissionrejected'))
                            //COUNT SHORTLIST STATUS
                            if(elements.shortListedColleges !== undefined){
                                
                                elements.shortListedColleges.forEach(valuess=>{
                                    if(valuess){
                                        if(valuess.status == 'pending'){
                                        elements.shortlistPeding = Number(JSON.parse(elements.shortlistPeding) + 1);
                                        }
                                        if(valuess.status == 'inProgress'){
                                        elements.shortlistinProgress = Number(JSON.parse(elements.shortlistinProgress) + 1);
                                        }
                                        if(valuess.status == 'admissionSuccessful'){
                                        elements.shortlistSuccessfull = Number(JSON.parse(elements.shortlistSuccessfull) + 1);
                                        }
                                        if(valuess.status == 'admissionRejected'){
                                        elements.shortlistreject = Number(JSON.parse(elements.shortlistreject) + 1);
                                        }   
                                    }
                                })
                            }
                            // self.userData.shortlistPeding = elements.shortlistPeding;
                            // self.userData.shortlistinProgress = elements.shortlistinProgress;
                            // self.userData.shortlistSuccessfull= elements.shortlistSuccessfull
                            // self.userData.shortlistreject = elements.shortlistreject;
                            // console.log(self.userData,"elements",elements)
                        }
                    })
                }
            })
            })
            .catch(error=>{console.log(error)})
       },
        addZero(val)
        {
            if(val<10)
            {
                return `0`+val
            }
            else
            {
                return val
            }
        },
        getMaxDate()
        {
            return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
        changeStatus(status)
        {
            var self = this

            self.userData.status = status

            db
                .collection(dbCollections.USERS)
                .doc(self.docId)
                .update({
                    updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    status:status
                })
                .then(()=>{
                })
                .catch(error=>{
                    console.log(error)
                })
        },
        addPayment(e)
        {
            var self = this
            self.$refs.paymentValidator.validate()
            .then((success)=>{
                if(success)
                {
                    self.userData.payments.push(self.addPaymentData)

                    db
                        .collection(dbCollections.USERS)
                        .doc(self.docId)
                        .update({
                            payments:self.userData.payments,
                            'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                        })
                        .then(()=>{
                            self.$bvModal.hide('modal-add-payment')
                        })
                        .catch(error=>{
                            console.log(error)
                        })
                }
            })
        },
        cancelPayment(e)
        {
            this.addPaymentData={
                paymentReceivedDate:'',
                modeOfPayment: '',
                bankName: '',
                chequeNo:'',
                remark:'',
                rupees: ''
            }
        },
        userUpdateActive(updateObj,callBack){
            var self = this;
            if(updateObj !== undefined && updateObj !== null){
                updateObj.updatedAt=firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,self.docId,updateObj,res=>{
                       if(updateObj.isActive == false){                       
                        callBack(true)
                        return;
                       }else{                       
                        callBack(true)
                        return;
                       }
                })
            }else{
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Something Went Wrong [please refresh the page]',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    })  
                    callBack(true)
                    return;
            }           
        }
    },
}
</script>
<style scoped>
.bottom-border{
    border-bottom: 1px solid #cfcfcf;
}
.card-bottom-border{
    border-bottom: 1px solid #cfcfcf;
    border-radius: unset;
    margin-top: 15px;
    padding-bottom: 15px;
}
.card-title-border-bottom{
    border-top:1px solid #cfcfcf;
    padding-top:1.5rem;
}
.paddingBackGround{
    background-color:blueviolet;
    color: white;
}
.successBackGround{
    background-color:green;
    color: white;
}
.progressBackGround{
    background-color: yellow;
    color: black;
}
.rejectedBackGround{
    background-color:red;
    color: white;
}
</style>